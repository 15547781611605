import { Grid } from "@mui/material";
import { Language } from "../../translations/translations";
import {
  LanguageIcon,
  LanguageSelectorContainer,
  LanguageTag,
  Logo,
} from "./language-selector.styles";
import icon from "../../img/icons/language.svg";
import logo from "../../img/logo.png";
import { useContext } from "react";
import { StateContext } from "../../App";
import { AppState } from "../../services/firebase/collections/app/app.service";

function LanguageSelectorComponent({
  setLanguage,
}: {
  setLanguage: (language: Language) => void;
}): JSX.Element {
  const { isSpanish } = useContext<AppState>(StateContext);

  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pb: 8,
      }}
    >
      <div>
        <Logo src={logo} alt="logo" />
      </div>
      <LanguageSelectorContainer>
        <LanguageIcon src={icon} alt="languageIcon" />
        <LanguageTag
          onClick={() => setLanguage(Language.ES)}
          selected={isSpanish}
        >
          {Language.ES.toUpperCase()}
        </LanguageTag>
        <LanguageTag>/</LanguageTag>
        <LanguageTag
          onClick={() => setLanguage(Language.EN)}
          selected={!isSpanish}
        >
          {Language.EN.toUpperCase()}
        </LanguageTag>
      </LanguageSelectorContainer>
    </Grid>
  );
}

export default LanguageSelectorComponent;
