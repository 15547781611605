import { ModalBody } from "./job-detail.styles";
import { Job } from "../../../../../../../services/firebase/collections/jobs/jobs.service";
import { t } from "i18next";

import ModalComponent from "../../../../../../modal/modal.component";
import TechnologyIcon from "../../../../../../technology-icon/technology-icon.component";
import { TechnologiesContainer } from "../../../../../../../styled-components";
interface JobDetailProps {
  job: Partial<Job>;
  open: boolean;
  close: () => void;
}

function JobDetailComponent({
  open,
  job: { description, usedTechnologies },
  close,
}: JobDetailProps): JSX.Element {
  return (
    <ModalComponent
      open={open}
      body={
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: description! }}></div>
          <h2>{t("usedTechnologies")}</h2>
          <TechnologiesContainer>
            {usedTechnologies?.map((technology) => (
              <div key={technology}>
                <TechnologyIcon technology={technology} />
              </div>
            ))}
          </TechnologiesContainer>
        </ModalBody>
      }
      close={close}
    ></ModalComponent>
  );
}

export default JobDetailComponent;
