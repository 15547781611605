import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initReactI18next } from "react-i18next";
import i18n, { t } from "i18next";
import { Language, Translations } from "./translations/translations";
import { ThemeProvider } from "@mui/material/styles";
import CookieBot from "react-cookiebot";
import { AppService } from "./services/firebase/collections/app/app.service";
import {
  BrowserRouter,
  Navigate,
  redirect,
  Route,
  Routes,
} from "react-router-dom";
import PrivacyPolicyComponent from "./views/privacy-policy/privacy-policy.component";
import LoginComponent from "./views/login/login.component";
import { AuthService } from "./services/firebase/auth/auth.service";
import ProtectedRouteComponent from "./components/protected-route/protected-route.component";
import AboutMeAdminComponent from "./views/admin/paths/about-me/about-me-editor.component";
import WorkExperienceEditor from "./views/admin/paths/work-experience/work-experience-editor.component";
import PersonalProjectEditorComponent from "./views/admin/paths/personal-projects/personal-project-editor.component";

const resources = { ...Translations.english, ...Translations.spanish };

i18n.use(initReactI18next).init({
  resources,
  lng: Language.EN,
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function ReactApp(): JSX.Element {
  const authService = useMemo(() => new AuthService(), []);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    authService.auth.onAuthStateChanged((user) => setIsAuthenticated(!!user));
  }, [authService]);

  const login = async (email: string, password: string): Promise<void> => {
    const token = await authService.login(email, password);
    token ? setIsAuthenticated(true) : alert(t("error"));
  };

  const logout = async (): Promise<void> => {
    await authService.logout();
    redirect("/");
  };

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={AppService.appTheme}>
          <Routes>
            <Route
              path="/"
              element={
                <App isAuthenticated={!!isAuthenticated} logout={logout} />
              }
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicyComponent />}
            />
            <Route
              path="/login"
              element={
                <LoginComponent
                  login={login}
                  isAuthenticated={!!isAuthenticated}
                />
              }
            />
            <Route path="admin">
              <Route index element={<Navigate to={"/admin/about-me"} />} />
              <Route
                path="about-me"
                element={
                  <ProtectedRouteComponent
                    isAuthenticated={!!isAuthenticated}
                    outlet={<AboutMeAdminComponent logout={logout} />}
                  />
                }
              />
              <Route
                path="work-experience"
                element={
                  <ProtectedRouteComponent
                    isAuthenticated={!!isAuthenticated}
                    outlet={<WorkExperienceEditor logout={logout} />}
                  />
                }
              />{" "}
              <Route
                path="personal-projects"
                element={
                  <ProtectedRouteComponent
                    isAuthenticated={!!isAuthenticated}
                    outlet={<PersonalProjectEditorComponent logout={logout} />}
                  />
                }
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
      <CookieBot
        domainGroupId={process.env["REACT_APP_COOKIEBOT_ID"] as string}
      />
    </React.StrictMode>
  );
}

root.render(<ReactApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
