import { Element } from "react-scroll";
import AboutMeComponent from "../../components/about-me/about-me.component";
import ExtraInfoComponent from "../../components/extra-info/extra-info.component";
import HeaderComponent from "../../components/header/header.component";
import ProjectsComponent from "../../components/projects/projects.component";
import WorkExperienceComponent from "../../components/work-experience/work-experience.component";

export const enum Section {
  Header = "header",
  AboutMe = "aboutMe",
  WorkExperience = "workExperience",
  ExtraInfo = "extraInfo",
  Projects = "projects",
}

function HomeComponent(): JSX.Element {
  return (
    <>
      <Element name={Section.Header}>
        <HeaderComponent />
      </Element>
      <Element name={Section.AboutMe}>
        <AboutMeComponent />
      </Element>
      <Element name={Section.WorkExperience}>
        <WorkExperienceComponent />
      </Element>
      <Element name={Section.ExtraInfo}>
        <ExtraInfoComponent />
      </Element>
      <Element name={Section.Projects}>
        <ProjectsComponent />
      </Element>
    </>
  );
}

export default HomeComponent;
