import styled from "styled-components";

export const LanguageDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  h2 {
    margin: 0px 0px 1rem 0px;
    &.no__margin {
      margin: 0;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    padding: 0;
    span {
      color: #ae56ff;
    }
  }
  p {
    font-weight: 400;
  }
  .high,
  .very-high {
    display: block;
    border-radius: 12px;
    width: 100%;
    height: 1rem;
    background: #8e8888;
    div {
      background: linear-gradient(
        158deg,
        rgba(174, 86, 255, 1) 35%,
        rgba(100, 141, 253, 1) 100%
      );
      display: block;
      border-radius: 12px;
      height: 100%;
    }
  }
  .high {
    div {
      width: 60%;
    }
  }
  .very-high {
    div {
      width: 90%;
    }
  }
  @media (max-width: 899px) {
    * {
      text-align: left;
    }
    h2 {
      font-size: 14px;
      line-height: 19px;
    }
    p {
      font-size: 11px;
      line-height: 19px;
    }
  }
`;
