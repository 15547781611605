import styled from "styled-components";

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #2b2b2b;
  border: 0.4px solid #c3c3c3;
  border-radius: 12px;
  padding: 1rem;
  justify-content: space-between;
  height: 100%;
  &:hover {
    box-shadow: 3px 3px 20px 1px rgba(133, 81, 221, 0.87);
  }
  button:hover {
    box-shadow: none;
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
  }
  h2 {
    font-size: 20px;
    line-height: 29px;
    font-weight: 400px;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
  }
`;
