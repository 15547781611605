import { CardMedia, CardContent, CardActions, Grid } from "@mui/material";
import { t } from "i18next";
import { CustomCard } from "./personal-project-card.styles";
import { Project } from "../../../../../../services/firebase/collections/projects/projects.service";

function ProjectCardComponent({
  project,
  deleteProject,
  openModal,
}: {
  project: Project;
  deleteProject: (id: string) => Promise<void>;
  openModal: (project: Project) => void;
}): JSX.Element {
  return (
    <Grid item xs={4} sx={{ p: 1 }}>
      <CustomCard>
        <div>
          <CardMedia
            sx={{ height: 140 }}
            image={
              project.img.includes("data:image")
                ? project.img
                : `data:image/png;base64,${project.img}`
            }
            title={project.title
              .toLocaleLowerCase()
              .replaceAll(" ", "-")
              .concat("project-image")}
          />
          <CardContent>
            <h2>{project.title}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: project.description }}
            ></div>
          </CardContent>
        </div>
        <CardActions>
          <button
            className="button__secondary"
            onClick={() => {
              const wantsToDelete = window.confirm("confirm");
              if (wantsToDelete) deleteProject(project!.id);
            }}
          >
            {t("delete")}
          </button>
          <button
            className="button__primary"
            onClick={(event) => {
              event.preventDefault();
              openModal(project);
            }}
          >
            {t("edit")}
          </button>
        </CardActions>
      </CustomCard>
    </Grid>
  );
}

export default ProjectCardComponent;
