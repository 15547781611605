import styled from "styled-components";
import { Grid } from "@mui/material";

export const FooterContainer = styled.div`
  background: #2d2d2d;
  display: flex;
  justify-content: center;
  height: auto;
`;

export const FooterColumn = styled(Grid)`
  h2 {
    font-size: 20px !important;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    * {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 1rem;

      p:hover,
      a:hover {
        cursor: pointer;
        color: #8551dd;
      }
    }
  }
  img {
    height: 40px;
    filter: invert(100%) sepia(2%) saturate(2372%) hue-rotate(213deg)
      brightness(107%) contrast(110%);
    &:hover {
      filter: invert(36%) sepia(66%) saturate(1396%) hue-rotate(236deg)
        brightness(89%) contrast(93%);
    }
  }
  .logo {
    width: auto;
    height: 85px;
    filter: none;
    &:hover {
      filter: none;
    }
  }
  .ml-1 {
    margin-left: 1rem;
  }
`;
