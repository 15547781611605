import { useCallback, useEffect, useMemo, useState } from "react";
import TextEditorComponent from "../../../../components/text-editor/text-editor.component";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import {
  AboutMe,
  AboutMeService,
} from "../../../../services/firebase/collections/aboutMe/aboutMe.service";
import AdminTemplateComponent from "../../components/admin-template.component";
import { Grid } from "@mui/material";
import { t } from "i18next";
import { TextAccent } from "../../../../styled-components";
import { useTranslation } from "react-i18next";
import { Language } from "../../../../translations/translations";
import LoaderComponent from "../../../../components/loader/loader.component";

interface AboutMeEditorComponentState {
  language: Language;
  isSpanish: boolean;
  isLoading: boolean;
  es?: AboutMe;
  en?: AboutMe;
}

function AboutMeEditorComponent({
  logout,
}: {
  logout: () => Promise<void>;
}): JSX.Element {
  const { i18n } = useTranslation();

  const [state, setState] = useState<AboutMeEditorComponentState>({
    language: Language.ES,
    isSpanish: true,
    isLoading: true,
  });

  const aboutMeService = useMemo(() => new AboutMeService(), []);

  const loadHTML = useCallback(async (): Promise<void> => {
    const data = await aboutMeService.getAll();
    setState((currentState) => ({
      ...currentState,
      es: data.es,
      en: data.en,
    }));
  }, [aboutMeService]);

  useEffect(() => {
    i18n.changeLanguage(state.language);
    if (!state.en || !state.es) loadHTML();
    setIsLoading(false);
  }, [i18n, loadHTML, state.es, state.en, state.language]);

  const changeLanguage = (language: Language) => {
    setState((currentState) => ({
      ...currentState,
      language,
      isSpanish: language === Language.ES,
    }));
  };

  const onChange = (event: ContentEditableEvent): void => {
    setState((currentState) =>
      state.isSpanish
        ? {
            ...currentState,
            es: {
              ...(currentState.es as AboutMe),
              text: event.target.value,
            },
          }
        : {
            ...currentState,
            en: { ...(currentState.en as AboutMe), text: event.target.value },
          }
    );
  };

  const setIsLoading = (isLoading: boolean): void => {
    setState((currentState) => ({ ...currentState, isLoading }));
  };

  const onSave = async (): Promise<void> => {
    setIsLoading(true);
    await aboutMeService.updateDocument(
      (state.isSpanish ? state.es : state.en) as AboutMe,
      state.language
    );
    setIsLoading(false);
  };

  return (
    <AdminTemplateComponent
      language={state.language}
      changeLanguage={changeLanguage}
      logout={logout}
      body={
        state.isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <Grid item xs={12}>
              <h1>
                <TextAccent>{t("aboutMe")}</TextAccent>
              </h1>
            </Grid>

            <Grid item xs={12} sx={{ pb: 3 }}>
              <TextEditorComponent
                name="aboutMe"
                html={(state.isSpanish ? state.es?.text : state.en?.text) ?? ""}
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={12}>
              <button className="button__primary" onClick={() => onSave()}>
                {t("save")}
              </button>
            </Grid>
          </>
        )
      }
    />
  );
}

export default AboutMeEditorComponent;
