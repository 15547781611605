import { Technologies } from "../../services/firebase/collections/jobs/jobs.service";
import { Tooltip } from "@mui/material";

import ajaxIcon from "../../img/icons/technologies/ajax.svg";
import angularIcon from "../../img/icons/technologies/angular.svg";
import csharpIcon from "../../img/icons/technologies/csharp.svg";
import cssIcon from "../../img/icons/technologies/css.svg";
import gitIcon from "../../img/icons/technologies/git.svg";
import htmlIcon from "../../img/icons/technologies/html.svg";
import javascriptIcon from "../../img/icons/technologies/javascript.svg";
import jestIcon from "../../img/icons/technologies/jest.svg";
import jqueryIcon from "../../img/icons/technologies/jquery.svg";
import jsonIcon from "../../img/icons/technologies/json.svg";
import laravelIcon from "../../img/icons/technologies/laravel.svg";
import mongodbIcon from "../../img/icons/technologies/mongodb.svg";
import ngrxIcon from "../../img/icons/technologies/ngrx.svg";
import nodejsIcon from "../../img/icons/technologies/nodejs.svg";
import phpIcon from "../../img/icons/technologies/php.svg";
import reduxIcon from "../../img/icons/technologies/redux.svg";
import sqlIcon from "../../img/icons/technologies/sql.svg";
import symfonyIcon from "../../img/icons/technologies/symfony.svg";
import typeormIcon from "../../img/icons/technologies/typeorm.svg";
import typescriptIcon from "../../img/icons/technologies/typescript.svg";
import wordpressIcon from "../../img/icons/technologies/wordpress.svg";

function TechnologyIcon({
  technology,
}: {
  technology: Technologies;
}): JSX.Element {
  const technologyIcon: Record<Technologies, JSX.Element> = {
    [Technologies.Angular]: (
      <Tooltip title={Technologies.Angular}>
        <img src={angularIcon} alt={Technologies.Angular} />
      </Tooltip>
    ),
    [Technologies.Jest]: (
      <Tooltip title={Technologies.Jest}>
        <img src={jestIcon} alt={Technologies.Jest} />
      </Tooltip>
    ),
    [Technologies.NgRx]: (
      <Tooltip title={Technologies.NgRx}>
        <img src={ngrxIcon} alt={Technologies.NgRx} />
      </Tooltip>
    ),
    [Technologies.TypeScript]: (
      <Tooltip title={Technologies.TypeScript}>
        <img src={typescriptIcon} alt={Technologies.TypeScript} />
      </Tooltip>
    ),
    [Technologies.Git]: (
      <Tooltip title={Technologies.Git}>
        <img src={gitIcon} alt={Technologies.Git} />
      </Tooltip>
    ),
    [Technologies.NodeJS]: (
      <Tooltip title={Technologies.NodeJS}>
        <img src={nodejsIcon} alt={Technologies.NodeJS} />
      </Tooltip>
    ),
    [Technologies.SQL]: (
      <Tooltip title={Technologies.SQL}>
        <img src={sqlIcon} alt={Technologies.SQL} />
      </Tooltip>
    ),
    [Technologies.CSS]: (
      <Tooltip title={Technologies.CSS}>
        <img src={cssIcon} alt={Technologies.CSS} />
      </Tooltip>
    ),
    [Technologies.TypeORM]: (
      <Tooltip title={Technologies.TypeORM}>
        <img src={typeormIcon} alt={Technologies.TypeORM} />
      </Tooltip>
    ),
    [Technologies.Redux]: (
      <Tooltip title={Technologies.Redux}>
        <img src={reduxIcon} alt={Technologies.Redux} />
      </Tooltip>
    ),
    [Technologies.PHP]: (
      <Tooltip title={Technologies.PHP}>
        <img src={phpIcon} alt={Technologies.PHP} />
      </Tooltip>
    ),
    [Technologies.HTML]: (
      <Tooltip title={Technologies.HTML}>
        <img src={htmlIcon} alt={Technologies.HTML} />
      </Tooltip>
    ),
    [Technologies.JavaScript]: (
      <Tooltip title={Technologies.JavaScript}>
        <img src={javascriptIcon} alt={Technologies.JavaScript} />
      </Tooltip>
    ),
    [Technologies.JQuery]: (
      <Tooltip title={Technologies.JQuery}>
        <img src={jqueryIcon} alt={Technologies.JQuery} />
      </Tooltip>
    ),
    [Technologies.AJAX]: (
      <Tooltip title={Technologies.AJAX}>
        <img src={ajaxIcon} alt={Technologies.AJAX} />
      </Tooltip>
    ),
    [Technologies.CSharp]: (
      <Tooltip title={Technologies.CSharp}>
        <img src={csharpIcon} alt={Technologies.CSharp} />
      </Tooltip>
    ),
    [Technologies.WordPress]: (
      <Tooltip title={Technologies.WordPress}>
        <img src={wordpressIcon} alt={Technologies.WordPress} />
      </Tooltip>
    ),
    [Technologies.Laravel]: (
      <Tooltip title={Technologies.Laravel}>
        <img src={laravelIcon} alt={Technologies.Laravel} />
      </Tooltip>
    ),
    [Technologies.Symfony]: (
      <Tooltip title={Technologies.Symfony}>
        <img src={symfonyIcon} alt={Technologies.Symfony} />
      </Tooltip>
    ),
    [Technologies.JSON]: (
      <Tooltip title={Technologies.JSON}>
        <img src={jsonIcon} alt={Technologies.JSON} />
      </Tooltip>
    ),
    [Technologies.MongoDB]: (
      <Tooltip title={Technologies.MongoDB}>
        <img src={mongodbIcon} alt={Technologies.MongoDB} />
      </Tooltip>
    ),
  };
  return technologyIcon[technology];
}

export default TechnologyIcon;
