import styled from "styled-components";

export const LanguageSelectorContainer = styled.div`
  display: flex;
  height: fit-content;
`;

export const LanguageIcon = styled.img`
  max-width: 1.5rem !important;
  margin-right: 1rem;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(160deg)
    brightness(112%) contrast(101%);
`;

export const LanguageTag = styled.span<{ selected?: boolean }>`
  height: 100%;
  display: block;
  font-weight: ${(props) => (props.selected ? "700" : "300")};
  color: ${(props) => (props.selected ? "#AE56FF" : "white")};
  cursor: ${(props) => (props.selected ? "not-allowed" : "pointer")};
  padding: 0.1rem;
`;

export const Logo = styled.img`
  max-height: 75px;
`;
