import { Container } from "@mui/material";
import "./App.css";
import FooterComponent from "./components/footer/footer.component";
import LanguageSelectorComponent from "./components/language-selector/language-selector.component";
import LoaderComponent from "./components/loader/loader.component";
import HomeComponent from "./views/home/home.component";
import { Language } from "./translations/translations";
import { useState, useEffect, createContext } from "react";
import { useTranslation } from "react-i18next";
import {
  AppState,
  AppService,
} from "./services/firebase/collections/app/app.service";
import NavBarComponent from "./components/nav-bar/nav-bar.component";
import { t } from "i18next";

export const StateContext = createContext<AppState>(AppService.initialState);

function App({
  isAuthenticated,
  logout,
}: {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
}): JSX.Element {
  const { i18n } = useTranslation();

  const [state, setState] = useState<AppState>(AppService.initialState);

  useEffect(() => {
    AppService.loadState().then((result) => setState(result));
  }, []);

  useEffect(() => {
    i18n.changeLanguage(state.language);
  }, [i18n, state.language]);

  const setLanguage = (language: Language) => {
    setState((currentState) => ({
      ...currentState,
      language,
      isSpanish: language === Language.ES,
    }));
  };

  return state.isLoading ? (
    <>
      <LoaderComponent />
    </>
  ) : (
    <StateContext.Provider value={state}>
      <Container fixed>
        <LanguageSelectorComponent setLanguage={setLanguage} />
        {isAuthenticated && (
          <NavBarComponent
            logout={logout}
            links={[
              {
                label: t("adminZone"),
                path: "/admin",
              },
            ]}
          />
        )}
        <HomeComponent />
      </Container>
      <FooterComponent />
    </StateContext.Provider>
  );
}

export default App;
