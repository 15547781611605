import styled from "styled-components";

export const ModalBody = styled.div`
  background: #2b2b2b;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-direction: column;
  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0px;
    padding: 0 0 1rem 0;
    text-align: left;
    width: 100%;
  }
  p {
    font-weight: 400;
    text-align: justify;
    padding: 0;
    margin: 0.5rem 0 0.5rem 0;
    a {
      color: #8551dd;
      text-decoration: underline;
    }
  }
`;
