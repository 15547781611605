import Grid from "@mui/material/Unstable_Grid2";
import { t } from "i18next";
import { useContext } from "react";
import { StateContext } from "../../App";
import { AppState } from "../../services/firebase/collections/app/app.service";
import { SectionTitle, TextAccent } from "../../styled-components";
import JobComponent from "./components/job.component";

function WorkExperienceComponent(): JSX.Element {
  const state = useContext<AppState>(StateContext);

  const jobs = state.isSpanish ? state.es.jobs : state.en.jobs;

  return (
    <>
      <Grid container spacing={1} sx={{ pt: 6 }}>
        <Grid xs={12} md={6}>
          <SectionTitle>
            <TextAccent width="80%">
              <span>{t("workExperience")}</span>
            </TextAccent>
          </SectionTitle>
        </Grid>
      </Grid>
      {jobs.map((job, index) => (
        <JobComponent key={job.id} leftImage={index % 2 !== 0} job={job} />
      ))}
    </>
  );
}

export default WorkExperienceComponent;
