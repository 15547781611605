import { Language } from "../../../../translations/translations";
import { Collection, CollectionService } from "../collection.service";

export const enum ProjectType {
  What2Visit = "what2visit",
  Wedding = "wedding",
  Triathlon = "triathlon",
}

export type Project = {
  id: string;
  title: string;
  description: string;
  url: string;
  order: number;
  img: string;
};

export type Projects = Project[];

export class ProjectsService extends CollectionService<Project> {
  protected readonly collection = Collection.Projects;

  async save(data: Projects | Project, language: Language): Promise<void> {
    try {
      await this.saveData(data, language);
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  }

  async getAll(): Promise<{ es: Projects; en: Projects }> {
    try {
      const [es, en] = await Promise.all([
        this.getDocuments(Language.ES),
        this.getDocuments(Language.EN),
      ]);
      return { es, en };
    } catch (error) {
      console.error("Error retrieving document: ", error);
      throw error;
    }
  }
}
