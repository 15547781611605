import { LoaderContainer } from "./loader.styles";
import { quantum } from "ldrs";

quantum.register();

function LoaderComponent(): JSX.Element {
  return (
    <LoaderContainer>
      <l-quantum size="150" speed="2" color="#8551dd"></l-quantum>
    </LoaderContainer>
  );
}

export default LoaderComponent;
