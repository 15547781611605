import { Language } from "../../../../translations/translations";
import { Collection, CollectionService } from "../collection.service";

export enum Technologies {
  Angular = "Angular",
  Jest = "Jest",
  NgRx = "NgRx",
  TypeScript = "TypeScript",
  Git = "git",
  NodeJS = "Node.js",
  SQL = "SQL",
  CSS = "CSS",
  Redux = "Redux",
  PHP = "PHP",
  HTML = "HTML",
  JavaScript = "JavaScript",
  JQuery = "jQuery",
  AJAX = "AJAX",
  CSharp = "C#",
  TypeORM = "TypeORM",
  WordPress = "WordPress",
  Laravel = "Laravel",
  Symfony = "Symfony",
  JSON = "JSON",
  MongoDB = "Mongo DB",
}

export type Job = {
  id: string;
  img: string;
  date: string;
  title: string;
  company: string;
  location: string;
  caption: string;
  order: number;
  description: string;
  usedTechnologies: Technologies[];
};

export type Jobs = Job[];

export class JobsService extends CollectionService<Job> {
  protected readonly collection = Collection.Jobs;

  async save(data: Jobs | Job, language: Language): Promise<void> {
    try {
      await this.saveData(data, language);
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  }

  async getAll(): Promise<{ es: Jobs; en: Jobs }> {
    try {
      const [es, en] = await Promise.all([
        this.getDocuments(Language.ES),
        this.getDocuments(Language.EN),
      ]);

      return { es, en };
    } catch (error) {
      console.error("Error retrieving document: ", error);
      throw error;
    }
  }
}
