import styled from "styled-components";

export const ButtonsContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: row;
  width: 80%;
  @media (max-width: 899px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ExperienceTag = styled.div`
  display: block;
  background: #2b2b2b;
  width: fit-content;
  height: fit-content;
  padding: 0.4rem;
  border-radius: 12px;
  position: absolute;
  bottom: 10%;
  z-index: 1;
  left: -6rem;
  border: solid #c3c3c3 0.4px;
  font-family: "Open Sans", sans-serif;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 1rem;
  }
  span {
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 1.25rem;
  }
  .years-tag {
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 2.5rem;
  }
  @media (max-width: 899px) {
    left: -0.5rem;
    bottom: 0;
    padding: 1rem;
  }
`;
