import { Grid } from "@mui/material";
import { t } from "i18next";
import { LanguageDiv } from "./languages.styles";
import { CustomCard } from "../../../../styled-components";

function LanguagesComponent(): JSX.Element {
  return (
    <Grid item xs={12} md={6}>
      <CustomCard>
        <LanguageDiv>
          <h2>
            {t("spanish")}: <span>{t("native")}</span>
          </h2>
        </LanguageDiv>
        <LanguageDiv>
          <h2 className="no__margin">{t("english")}</h2>
          <p>{t("writtenComprehension").concat(": ").concat(t("veryHigh"))}</p>
          <div className="very-high">
            <div></div>
          </div>
          <p>{t("writtenExpression").concat(": ").concat(t("veryHigh"))}</p>
          <div className="very-high">
            <div></div>
          </div>
          <p>{t("oralComprehension").concat(": ").concat(t("high"))}</p>
          <div className="high">
            <div></div>
          </div>
          <p>{t("oralExpression").concat(": ").concat(t("high"))}</p>
          <div className="high">
            <div></div>
          </div>
        </LanguageDiv>
      </CustomCard>
    </Grid>
  );
}

export default LanguagesComponent;
