import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    width: 50%;
    min-width: 50svw;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-evenly;
    * {
      color: white;
    }
    @media (max-width: 899px) {
      width: 90%;
    }
  }
`;
