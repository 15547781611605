import { createTheme } from "@mui/material";
import { Language } from "../../../../translations/translations";
import { AboutMe, AboutMeService } from "../aboutMe/aboutMe.service";
import { Jobs, JobsService } from "../jobs/jobs.service";
import { Projects, ProjectsService } from "../projects/projects.service";
import { Studies, StudiesService } from "../studies/studies.service";

export interface AppState {
  es: {
    jobs: Jobs;
    studies: Studies;
    projects: Projects;
    aboutMe: AboutMe;
  };
  en: {
    jobs: Jobs;
    studies: Studies;
    projects: Projects;
    aboutMe: AboutMe;
  };
  isLoading: boolean;
  language: Language;
  isSpanish: boolean;
}

export class AppService {
  static readonly initialState: AppState = {
    es: {
      jobs: [],
      studies: [],
      projects: [],
      aboutMe: { text: "", id: "" },
    },
    en: {
      jobs: [],
      studies: [],
      projects: [],
      aboutMe: { text: "", id: "" },
    },
    isLoading: true,
    language: Language.EN,
    isSpanish: false,
  };

  static readonly appTheme = createTheme({
    palette: {
      primary: {
        main: "#AE56FF",
      },
      secondary: {
        main: "#648DFD",
      },
    },
  });

  static async loadState(): Promise<AppState> {
    const jobsService = new JobsService();
    const studiesService = new StudiesService();
    const aboutMeService = new AboutMeService();
    const projectsService = new ProjectsService();
    const [jobs, studies, aboutMe, projects] = await Promise.all([
      jobsService.getAll(),
      studiesService.getAll(),
      aboutMeService.getAll(),
      projectsService.getAll(),
    ]);

    return {
      es: {
        jobs: jobs.es,
        studies: studies.es,
        aboutMe: aboutMe.es,
        projects: projects.es,
      },
      en: {
        jobs: jobs.en,
        studies: studies.en,
        aboutMe: aboutMe.en,
        projects: projects.en,
      },
      isLoading: false,
      language: Language.EN,
      isSpanish: false,
    };
  }
}
