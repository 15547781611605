import {
  TextField,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import { ChangeEvent, FormEvent, useState } from "react";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { ModalBody } from "./entity-dialog.styles";
import TextEditorComponent from "../../text-editor/text-editor.component";

/**
 * Enum representing the different types of form fields that can be used in the form.
 * @enum {string}
 */
export const enum FormFieldType {
  Text = "text",
  RichText = "richText",
  Select = "select",
  Input = "input",
}

/**
 * BaseType defines the fundamental properties that any form field must have.
 * @typedef {Object} BaseType
 * @property {string} label - The label that will be displayed for the form field.
 * @property {FormFieldType} type - The type of the form field, indicating its behavior and rendering.
 * @property {boolean} required - Whether the form field is mandatory for the form submission.
 *
 * This type is the foundation for all form field configurations. Every form field, regardless of its specific type
 * (e.g., text input, select dropdown, rich text editor), will inherit these basic properties.
 */
type BaseType = {
  label: string;
  type: FormFieldType;
  required: boolean;
};

/**
 * SelectConfig provides additional configuration options specifically for select-type form fields.
 * @typedef {Object} SelectConfig
 * @property {boolean} multiple - Indicates whether the select field should allow multiple selections.
 * @property {Array<{ key: string, value: string }>} options - The list of selectable options, each defined by a key-value pair.
 */
type SelectConfig = {
  multiple: boolean;
  options: { key: string; value: string }[];
};

/**
 * ControlConfig is a utility type that conditionally extends a form field configuration based on its type.
 * @template T - The specific type of the form field.
 * @typedef {Object} ControlConfig
 *
 * If the form field type is `select`, it extends the configuration with properties from `SelectConfig`.
 * For other form field types, it does not add any additional properties for the moment.
 *
 * This type is essential for creating flexible form configurations where only relevant properties are included
 * for each type of field, keeping the configuration clean and focused.
 */
type ControlConfig<T extends FormFieldType> = T extends "select"
  ? SelectConfig
  : {};

/**
 * Represents a form field configuration, combining the base properties with any type-specific configurations.
 * @template T - The type of the form field, which determines the additional configuration options.
 * @typedef {BaseType & ControlConfig<T>} FormField
 */
export type FormField<T extends FormFieldType = FormFieldType> = BaseType &
  ControlConfig<T>;

/**
 * Represents a form field array configuration, only for internal use.
 * It extends FormField type to add the key param, used to manage the input state
 */
export type FormFields = (FormField & { key: string })[];

function EntityDialogComponent<
  FormInterface,
  DataType extends { id: string } = any,
  SelectType = any
>({
  fields,
  data,
  close,
  handleSubmit,
}: {
  fields: FormInterface;
  data: Partial<DataType>;
  close: () => void;
  handleSubmit: (
    updatedData: Partial<DataType>,
    previousData?: Partial<DataType>
  ) => Promise<void>;
}): JSX.Element {
  const [state, setState] = useState<Partial<DataType>>(data);

  const convertToBase64 = (file: Blob) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
    });
  };

  const handleTextChange = (
    event:
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<SelectType>
  ) => {
    setState((currentState) => {
      return {
        ...currentState,
        id: state && state.id === "" ? undefined : state.id,
        [event.target.name as string]: event.target.value,
      };
    });
  };

  const handleWYSIWYGChange = (event: ContentEditableEvent): void => {
    setState((currentState) => ({
      ...currentState,
      [event.target.name as string]: event.target.value,
    }));
  };

  const handleFileInputChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (event.target.files) {
      const result = await convertToBase64(event.target.files[0]);
      setState((currentState) => ({
        ...currentState,
        ...(state as any),
        [event.target.name as string]: result,
      }));
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    handleSubmit(state, data);
  };

  return (
    <ModalBody>
      <form onSubmit={onSubmit}>
        {Object.entries<FormField>(fields as any)
          .reduce<FormFields>(
            (controls, [key, value]) => [...controls, { ...value, key }],
            []
          )
          .map((field, index) => {
            switch (field.type) {
              case FormFieldType.Text:
                return (
                  <TextField
                    key={field.key + index}
                    id={field.key}
                    label={t(field.key)}
                    variant="standard"
                    name={field.key}
                    value={(state as any)[field.key]}
                    required={field.required}
                    onChange={handleTextChange}
                  />
                );
              case FormFieldType.Select:
                return (
                  <Select
                    key={field.key + index}
                    id={field.key}
                    label={t(field.key)}
                    variant="standard"
                    name={field.key}
                    multiple
                    value={(state as any)[field.key]}
                    onChange={(event: SelectChangeEvent<SelectType>) =>
                      handleTextChange(event)
                    }
                    input={<OutlinedInput label="Name" />}
                  >
                    {(field as FormField<FormFieldType.Select>).options?.map(
                      ({ key, value }) => (
                        <MenuItem
                          style={{ color: "black" }}
                          key={key}
                          value={value}
                        >
                          {key}
                        </MenuItem>
                      )
                    )}
                  </Select>
                );
              case FormFieldType.RichText:
                return (
                  <div key={field.key + index}>
                    <FormLabel>{t(field.label)}</FormLabel>
                    <TextEditorComponent
                      html={(state as any)[field.key] ?? ""}
                      name={field.key}
                      height="20svh"
                      onChange={handleWYSIWYGChange}
                    />
                  </div>
                );
              case FormFieldType.Input:
                return (
                  <div
                    key={field.key + index}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>{t(field.label)}</FormLabel>
                    <input
                      id={field.key}
                      name={field.key}
                      type="file"
                      onChange={handleFileInputChange}
                    />
                  </div>
                );
              default:
                return <></>;
            }
          })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "1rem",
          }}
        >
          <button className="button__secondary" onClick={close}>
            {t("cancel")}
          </button>{" "}
          <button className="button__primary" type="submit">
            {t("save")}
          </button>
        </div>
      </form>
    </ModalBody>
  );
}

export default EntityDialogComponent;
