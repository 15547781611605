import { Grid } from "@mui/material";
import { JobImage } from "./job-image.styles";

function JobImageComponent({
  img,
  title,
}: {
  img: string;
  title: string;
}): JSX.Element {
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        pt: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <JobImage>
        <div>
          <img
            alt={title
              .toLocaleLowerCase()
              .replaceAll(" ", "-")
              .concat("job-image")}
            src={
              img.includes("data:image") ? img : `data:image/png;base64,${img}`
            }
          />
        </div>
      </JobImage>
    </Grid>
  );
}

export default JobImageComponent;
