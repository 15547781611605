import { Avatar } from "@mui/material";
import styled from "styled-components";

export const TextAccent = styled.span<{ width?: string }>`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    width: ${(props) => (props.width ? props.width : "100%")};
    right: 0;
    height: 5px;
    border-radius: 2px;
    background: linear-gradient(111.3deg, #ae56ff 9.6%, #648dfd 93.6%);
  }
`;

export const CustomAvatarContainer = styled.div<{
  $mobilemarginbottom?: string;
  $mobilemargintop?: string;
}>`
  display: flex;
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 899px) {
    margin-top: ${(props) =>
      props.$mobilemargintop ? props.$mobilemargintop : "2rem"};$
    margin-bottom: ${(props) =>
      props.$mobilemarginbottom ? props.$mobilemarginbottom : "0"};
  }
`;

export const CustomAvatar = styled(Avatar)<{ width?: string }>`
  background: linear-gradient(
    158deg,
    rgba(174, 86, 255, 1) 35%,
    rgba(100, 141, 253, 1) 100%
  );
  width: ${(props) =>
    props.width ? props.width + " !important" : "100% !important"};
  height: auto !important;
`;

export const SectionTitle = styled.h2`
  font-size: 35px;
  line-height: 41px;
`;

export const CustomCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #2b2b2b;
  border: 0.4px solid #c3c3c3;
  border-radius: 12px;
  padding: 2rem;
  height: 100%;
  justify-content: space-evenly;
`;

export const TechnologiesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex: 1 1 0px;
  img {
    height: 40px;
  }
`;
