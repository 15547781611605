import { useCallback, useEffect, useMemo, useState } from "react";
import AdminTemplateComponent from "../../components/admin-template.component";
import { Grid } from "@mui/material";
import { t } from "i18next";
import { TextAccent } from "../../../../styled-components";
import { useTranslation } from "react-i18next";
import { Language } from "../../../../translations/translations";
import LoaderComponent from "../../../../components/loader/loader.component";

import ModalComponent from "../../../../components/modal/modal.component";
import {
  Project,
  Projects,
  ProjectsService,
} from "../../../../services/firebase/collections/projects/projects.service";
import ProjectCardComponent from "./components/personal-project-card/personal-project-card.component";
import ProjectModalComponent from "./components/project-modal/project-modal.component";

interface WorkExperienceEditorState {
  language: Language;
  isSpanish: boolean;
  isLoading: boolean;
  isModalOpen: boolean;
  project: Partial<Project>;
  es: Projects;
  en: Projects;
}

const initialState: WorkExperienceEditorState = {
  language: Language.ES,
  isSpanish: true,
  isLoading: true,
  isModalOpen: false,
  es: [],
  en: [],
  project: {
    description: "",
    id: "",
    img: "",
    order: 0,
    title: "",
    url: "",
  },
};

function PersonalProjectEditorComponent({
  logout,
}: {
  logout: () => Promise<void>;
}): JSX.Element {
  const { i18n } = useTranslation();

  const projectsService = useMemo(() => new ProjectsService(), []);

  const [state, setState] = useState<WorkExperienceEditorState>(initialState);

  const changeLanguage = (language: Language) => {
    setState((currentState) => ({
      ...currentState,
      language,
      isSpanish: language === Language.ES,
    }));
  };

  const setIsLoading = (isLoading: boolean): void => {
    setState((currentState) => ({ ...currentState, isLoading }));
  };

  const loadProjects = useCallback(async (): Promise<void> => {
    setState((currentState) => ({
      ...currentState,
      isLoading: true,
    }));
    const data = await projectsService.getAll();
    setState((currentState) => ({
      ...currentState,
      es: data.es,
      en: data.en,
      isLoading: false,
    }));
  }, [projectsService]);

  const deleteProject = async (id: string): Promise<void> => {
    setIsLoading(true);
    await projectsService.deleteDocument(id, state.language);
    await loadProjects();
  };

  const openModal = (project?: Project): void => {
    setState((currentState) => ({
      ...currentState,
      project: project ?? initialState.project,
      isModalOpen: true,
    }));
  };

  const handleSubmit = async (
    updatedProject?: Partial<Project>,
    previousProject?: Partial<Project>
  ): Promise<void> => {
    if (JSON.stringify(updatedProject) === JSON.stringify(previousProject))
      return;
    setIsLoading(true);
    if (updatedProject?.id)
      await projectsService.updateDocument(
        updatedProject as Project,
        state.language
      );
    else
      await projectsService.save(
        {
          ...updatedProject,
          id: null,
          order: state.isSpanish
            ? state.es[0].order + 1
            : state.en[0].order + 1,
        } as unknown as Project,
        state.language
      );
    setState((currentState) => ({
      ...currentState,
      isModalOpen: false,
      project: initialState.project,
    }));
    await loadProjects();
  };

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  useEffect(() => {
    i18n.changeLanguage(state.language);
  }, [i18n, state.language]);

  return (
    <>
      <AdminTemplateComponent
        language={state.language}
        changeLanguage={changeLanguage}
        logout={logout}
        body={
          state.isLoading ? (
            <LoaderComponent />
          ) : (
            <>
              <Grid container>
                <Grid item xs={12} sx={{ p: 1 }}>
                  <h1>
                    <TextAccent>{t("personalProjects")}</TextAccent>
                  </h1>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ p: 1, justifyContent: "end", display: "flex" }}
                >
                  <Grid item xs={3}>
                    <button
                      className="button__primary"
                      onClick={() => openModal()}
                    >
                      {t("createProject")}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                {(state.isSpanish ? state.es : state.en)?.map((project) => (
                  <ProjectCardComponent
                    key={project.id}
                    project={project}
                    deleteProject={deleteProject}
                    openModal={openModal}
                  />
                ))}
              </Grid>
            </>
          )
        }
      />
      <ModalComponent
        open={state.isModalOpen}
        body={
          <ProjectModalComponent
            project={state.project}
            handleSubmit={handleSubmit}
            close={() => {
              setState((currentState) => ({
                ...currentState,
                isModalOpen: false,
              }));
            }}
          />
        }
        close={() => {
          setState((currentState) => ({ ...currentState, isModalOpen: false }));
        }}
      ></ModalComponent>
    </>
  );
}

export default PersonalProjectEditorComponent;
