import styled from "styled-components";

export const StudyDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    margin: 0px 0px 1rem 0px;
    padding: 0;
  }
  div {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    margin: 0px 0px 1rem 0px;
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
    gap: 1rem;
    background: linear-gradient(
      158deg,
      rgba(174, 86, 255, 1) 35%,
      rgba(100, 141, 253, 1) 100%
    );
    border-radius: 8px;
  }

  @media (max-width: 899px) {
    * {
      text-align: left;
    }
    img {
      display: none;
    }
    h2 {
      font-size: 14px;
      line-height: 19px;
    }
    p {
      font-size: 11px;
      line-height: 19px;
    }
  }
`;
