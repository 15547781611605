import { Modal, Fade, Box } from "@mui/material";

interface ModalProps {
  open: boolean;
  body: JSX.Element;
  close: () => void;
}

function ModalComponent({ open, close, body }: ModalProps) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={close}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", md: "auto" },
            bgcolor: "transparent",
          }}
        >
          {body}
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalComponent;
