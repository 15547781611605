import { Grid } from "@mui/material";
import { Job } from "../../../services/firebase/collections/jobs/jobs.service";
import JobDescriptionComponent from "./components/description/job-description.component";
import JobImageComponent from "./components/image/job-image.component";

function JobComponent({
  leftImage,
  job: {
    title,
    img,
    description,
    location,
    company,
    date,
    caption,
    usedTechnologies,
  },
}: {
  leftImage: boolean;
  job: Job;
}): JSX.Element {
  return (
    <>
      <Grid container spacing={1} sx={{ display: { xs: "none", md: "flex" } }}>
        {leftImage ? (
          <>
            <JobImageComponent img={img} title={title} />
            <JobDescriptionComponent
              title={title}
              date={date}
              company={company}
              location={location}
              caption={caption}
              description={description}
              usedTechnologies={usedTechnologies}
            />
          </>
        ) : (
          <>
            <JobDescriptionComponent
              title={title}
              date={date}
              company={company}
              location={location}
              caption={caption}
              description={description}
              usedTechnologies={usedTechnologies}
            />
            <JobImageComponent img={img} title={title} />
          </>
        )}
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ display: { xs: "initial", md: "none" }, pt: 8 }}
      >
        <JobImageComponent img={img} title={title} />
        <JobDescriptionComponent
          title={title}
          date={date}
          company={company}
          location={location}
          caption={caption}
          description={description}
          usedTechnologies={usedTechnologies}
        />
      </Grid>
    </>
  );
}

export default JobComponent;
