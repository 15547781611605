import { Language } from "../../../../translations/translations";
import { Collection, CollectionService } from "../collection.service";

export type AboutMe = {
  id: string;
  text: string;
};

export class AboutMeService extends CollectionService<AboutMe> {
  protected readonly collection = Collection.AboutMe;

  async save(aboutMe: AboutMe, language: Language): Promise<void> {
    try {
      await this.saveData(aboutMe, language);
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  }

  async getAll(): Promise<{ es: AboutMe; en: AboutMe }> {
    try {
      const [es, en] = await Promise.all([
        this.getDocuments(Language.ES, false),
        this.getDocuments(Language.EN, false),
      ]);
      return { es: es.shift() as AboutMe, en: en.shift() as AboutMe };
    } catch (error) {
      console.error("Error retrieving document: ", error);
      throw error;
    }
  }
}
