import { useCallback, useEffect, useMemo, useState } from "react";
import AdminTemplateComponent from "../../components/admin-template.component";
import { Grid } from "@mui/material";
import { t } from "i18next";
import { TextAccent } from "../../../../styled-components";
import { useTranslation } from "react-i18next";
import { Language } from "../../../../translations/translations";
import LoaderComponent from "../../../../components/loader/loader.component";
import {
  Job,
  Jobs,
  JobsService,
} from "../../../../services/firebase/collections/jobs/jobs.service";
import JobCardComponent from "./components/job-card/job-card.component";
import ModalComponent from "../../../../components/modal/modal.component";
import WorkModalComponent from "./components/work-modal/work-modal.component.";

interface WorkExperienceEditorState {
  language: Language;
  isSpanish: boolean;
  isLoading: boolean;
  isModalOpen: boolean;
  job: Partial<Job>;
  es: Jobs;
  en: Jobs;
}

const initialState: WorkExperienceEditorState = {
  language: Language.ES,
  isSpanish: true,
  isLoading: true,
  isModalOpen: false,
  es: [],
  en: [],
  job: {
    img: "",
    date: "",
    title: "",
    company: "",
    location: "",
    caption: "",
    description: "",
    usedTechnologies: [],
  },
};

function WorkExperienceEditor({
  logout,
}: {
  logout: () => Promise<void>;
}): JSX.Element {
  const { i18n } = useTranslation();

  const jobsService = useMemo(() => new JobsService(), []);

  const [state, setState] = useState<WorkExperienceEditorState>(initialState);

  const changeLanguage = (language: Language) => {
    setState((currentState) => ({
      ...currentState,
      language,
      isSpanish: language === Language.ES,
    }));
  };

  const setIsLoading = (isLoading: boolean): void => {
    setState((currentState) => ({ ...currentState, isLoading }));
  };

  const loadJobs = useCallback(async (): Promise<void> => {
    setState((currentState) => ({
      ...currentState,
      isLoading: true,
    }));
    const data = await jobsService.getAll();
    setState((currentState) => ({
      ...currentState,
      es: data.es,
      en: data.en,
      isLoading: false,
    }));
  }, [jobsService]);

  const deleteJob = async (id: string): Promise<void> => {
    setIsLoading(true);
    await jobsService.deleteDocument(id, state.language);
    await loadJobs();
  };

  const openModal = (job?: Job): void => {
    setState((currentState) => ({
      ...currentState,
      job: job ?? initialState.job,
      isModalOpen: true,
    }));
  };

  const handleSubmit = async (
    updatedJob?: Partial<Job>,
    previousJob?: Partial<Job>
  ): Promise<void> => {
    if (JSON.stringify(updatedJob) === JSON.stringify(previousJob)) return;
    setIsLoading(true);
    if (updatedJob?.id)
      await jobsService.updateDocument(updatedJob as Job, state.language);
    else
      await jobsService.save(
        {
          ...updatedJob,
          id: null,
          order: state.isSpanish
            ? state.es[0].order + 1
            : state.en[0].order + 1,
        } as unknown as Job,
        state.language
      );
    setState((currentState) => ({
      ...currentState,
      isModalOpen: false,
      job: initialState.job,
    }));
    await loadJobs();
  };

  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  useEffect(() => {
    i18n.changeLanguage(state.language);
  }, [i18n, state.language]);

  return (
    <>
      <AdminTemplateComponent
        language={state.language}
        changeLanguage={changeLanguage}
        logout={logout}
        body={
          state.isLoading ? (
            <LoaderComponent />
          ) : (
            <>
              <Grid container>
                <Grid item xs={12} sx={{ p: 1 }}>
                  <h1>
                    <TextAccent>{t("workExperience")}</TextAccent>
                  </h1>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ p: 1, justifyContent: "end", display: "flex" }}
                >
                  <Grid item xs={3}>
                    <button
                      className="button__primary"
                      onClick={() => openModal()}
                    >
                      {t("createJob")}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                {(state.isSpanish ? state.es : state.en)?.map((job) => (
                  <JobCardComponent
                    key={job.id}
                    job={job}
                    deleteJob={deleteJob}
                    openModal={openModal}
                  />
                ))}
              </Grid>
            </>
          )
        }
      />
      <ModalComponent
        open={state.isModalOpen}
        body={
          <WorkModalComponent
            job={state.job}
            handleSubmit={handleSubmit}
            close={() => {
              setState((currentState) => ({
                ...currentState,
                isModalOpen: false,
              }));
            }}
          />
        }
        close={() => {
          setState((currentState) => ({ ...currentState, isModalOpen: false }));
        }}
      ></ModalComponent>
    </>
  );
}

export default WorkExperienceEditor;
