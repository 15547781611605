import styled from "styled-components";

export const ImageContainer = styled.div`
  display: block;
  border: 3px solid white;
  margin: 0;
  padding: 1rem 1rem 0 1rem;
  border-radius: 25px 25px 0px 0px;
  width: 80%;
  height: 40%;
  min-height: 300px;
  @media (max-width: 899px) {
    min-height: 190px;
  }
  div {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 25px 25px 0px 0px;
    background: linear-gradient(
      158deg,
      rgba(174, 86, 255, 1) 35%,
      rgba(100, 141, 253, 1) 100%
    );
    img {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      display: block;
      width: 100%;
    }
  }
`;
