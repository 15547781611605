import { Grid } from "@mui/material";
import { FooterColumn } from "../../footer.styles";
import logo from "../../../../img/logo.png";

function LogoComponent(): JSX.Element {
  return (
    <FooterColumn container xs={12} md={3}>
      <Grid item xs={12} sx={{ pb: { xs: 4 } }}>
        <img className="logo" src={logo} alt="logo" />
      </Grid>
    </FooterColumn>
  );
}

export default LogoComponent;
