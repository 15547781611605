import { Grid } from "@mui/material";
import styled from "styled-components";

export const NavbarContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  * {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    &:hover {
      color: #8551dd !important;
      * {
        color: #8551dd;
      }
    }
  }
`;
