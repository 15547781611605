import styled from "styled-components";

export const ModalBody = styled.div`
  background: #2b2b2b;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-direction: column;
  form {
    display: flex;
    width: 100%;
    min-width: 50svw;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-evenly;
    * {
      color: white;
    }
    .rsw-toolbar * {
      color: black;
    }
  }
`;
