import { t } from "i18next";
import { Container, Grid } from "@mui/material";
import NavBarComponent from "../../../components/nav-bar/nav-bar.component";
import LanguageSelectorComponent from "../../../components/language-selector/language-selector.component";
import { Language } from "../../../translations/translations";

function AdminTemplateComponent({
  language,
  body,
  changeLanguage,
  logout,
}: {
  body: JSX.Element;
  language: Language;
  changeLanguage: (language: Language) => void;
  logout: () => Promise<void>;
}): JSX.Element {
  return (
    <>
      <NavBarComponent
        links={[
          {
            label: t("home"),
            path: "/",
          },
          {
            label: t("aboutMe"),
            path: "/admin/about-me",
          },
          {
            label: t("workExperience"),
            path: "/admin/work-experience",
          },
          {
            label: t("personalProjects"),
            path: "/admin/personal-projects",
          },
          /*{
            label: t("studies"),
            path: "/admin/studies",
          },,*/
        ]}
        logout={logout}
      />

      <Container fixed sx={{ pt: 8, pb: 8 }}>
        <LanguageSelectorComponent setLanguage={changeLanguage} />
        <Grid item xs={12}>
          {body}
        </Grid>
      </Container>
    </>
  );
}

export default AdminTemplateComponent;
