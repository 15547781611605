import styled from "styled-components";

export const JobImage = styled.div`
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 1rem;
  border: 3px solid white;
  @media (max-width: 899px) {
    width: 230px;
    height: 230px;
  }
  div {
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: white;
  }
  img {
    border-radius: 25px;
    width: 100%;
  }
`;
