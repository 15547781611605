import { Project } from "../../../../services/firebase/collections/projects/projects.service";

import { Grid } from "@mui/material";
import { CardBody } from "./project-card.styles";
import { t } from "i18next";

function ProjectCardComponent({
  project: { img, title, description, url },
}: {
  project: Project;
}): JSX.Element {
  return (
    <Grid item xs={12} md={4} sx={{ pb: { xs: 8 } }}>
      <a href={url} target="_blank" rel="noreferrer">
        <CardBody>
          <div>
            <img
              alt={title
                .toLocaleLowerCase()
                .replaceAll(" ", "-")
                .concat("job-image")}
              src={
                img.includes("data:image")
                  ? img
                  : `data:image/png;base64,${img}`
              }
            />
            <h2>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
          <button className="button__secondary">{t("visit")}</button>
        </CardBody>
      </a>
    </Grid>
  );
}

export default ProjectCardComponent;
