import { Navigate } from "react-router-dom";
import LoginFormComponent from "../../components/login-form/login-form.component";

function LoginComponent({
  login,
  isAuthenticated,
}: {
  login: (email: string, password: string) => Promise<void>;
  isAuthenticated: boolean;
}): JSX.Element {
  return isAuthenticated ? (
    <Navigate to={{ pathname: "/admin" }} />
  ) : (
    <LoginFormComponent login={login} isAuthenticated={isAuthenticated} />
  );
}

export default LoginComponent;
