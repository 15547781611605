import { t } from "i18next";
import {
  Job,
  Technologies,
} from "../../../../../../services/firebase/collections/jobs/jobs.service";
import EntityDialogComponent, {
  FormField,
  FormFieldType,
} from "../../../../../../components/modal/variants/entity-dialog.component";

const enum WorkFormField {
  Title = "title",
  Company = "company",
  Date = "date",
  Location = "location",
  Caption = "caption",
  Description = "description",
  UsedTechnologies = "usedTechnologies",
  Image = "img",
  Order = "order",
}

interface WorkForm {
  [WorkFormField.Title]: FormField<FormFieldType.Text>;
  [WorkFormField.Company]: FormField<FormFieldType.Text>;
  [WorkFormField.Date]: FormField<FormFieldType.Text>;
  [WorkFormField.Location]: FormField<FormFieldType.Text>;
  [WorkFormField.Caption]: FormField<FormFieldType.Text>;
  [WorkFormField.Description]: FormField<FormFieldType.RichText>;
  [WorkFormField.UsedTechnologies]: FormField<FormFieldType.Select>;
  [WorkFormField.Image]: FormField<FormFieldType.Input>;
}

function WorkModalComponent({
  job,
  close,
  handleSubmit,
}: {
  job: Partial<Job>;
  close: () => void;
  handleSubmit: (
    updatedJob: Partial<Job>,
    previousJob?: Partial<Job>
  ) => Promise<void>;
}): JSX.Element {
  const fields: WorkForm = {
    [WorkFormField.Title]: {
      label: t(WorkFormField.Title),
      type: FormFieldType.Text,
      required: true,
    },
    [WorkFormField.Company]: {
      label: t(WorkFormField.Company),
      type: FormFieldType.Text,
      required: true,
    },
    [WorkFormField.Date]: {
      label: t(WorkFormField.Date),
      type: FormFieldType.Text,
      required: true,
    },
    [WorkFormField.Location]: {
      label: t(WorkFormField.Location),
      type: FormFieldType.Text,
      required: true,
    },
    [WorkFormField.Caption]: {
      label: t(WorkFormField.Caption),
      type: FormFieldType.Text,
      required: true,
    },
    [WorkFormField.Description]: {
      label: t(WorkFormField.Description),
      type: FormFieldType.RichText,
      required: true,
    },
    [WorkFormField.UsedTechnologies]: {
      label: t(WorkFormField.UsedTechnologies),
      type: FormFieldType.Select,
      required: true,
      multiple: true,
      options: Object.entries(Technologies).map(([key, value]) => ({
        key,
        value,
      })),
    },
    [WorkFormField.Image]: {
      label: t(WorkFormField.Image),
      type: FormFieldType.Input,
      required: true,
    },
  };

  return (
    <EntityDialogComponent<WorkForm, Job, Technologies>
      fields={fields}
      data={job}
      close={close}
      handleSubmit={handleSubmit}
    />
  );
}

export default WorkModalComponent;
