import { t } from "i18next";
import { Project } from "../../../../../../services/firebase/collections/projects/projects.service";
import EntityDialogComponent, {
  FormField,
  FormFieldType,
} from "../../../../../../components/modal/variants/entity-dialog.component";

const enum ProjectFormField {
  Title = "title",
  Description = "description",
  URL = "url",
  Order = "order",
  Image = "img",
}

interface ProjectForm {
  [ProjectFormField.Title]: FormField<FormFieldType.Text>;
  [ProjectFormField.Description]: FormField<FormFieldType.Text>;
  [ProjectFormField.URL]: FormField<FormFieldType.Text>;
  [ProjectFormField.Image]: FormField<FormFieldType.Input>;
}

function ProjectModalComponent({
  project,
  close,
  handleSubmit,
}: {
  project: Partial<Project>;
  close: () => void;
  handleSubmit: (
    updatedProject: Partial<Project>,
    previousProject?: Partial<Project>
  ) => Promise<void>;
}): JSX.Element {
  const fields: ProjectForm = {
    [ProjectFormField.Title]: {
      label: t(ProjectFormField.Title),
      type: FormFieldType.Text,
      required: true,
    },
    [ProjectFormField.Description]: {
      label: t(ProjectFormField.Description),
      type: FormFieldType.RichText,
      required: true,
    },
    [ProjectFormField.URL]: {
      label: t(ProjectFormField.URL),
      type: FormFieldType.Text,
      required: true,
    },
    [ProjectFormField.Image]: {
      label: t(ProjectFormField.Image),
      type: FormFieldType.Input,
      required: true,
    },
  };

  return (
    <EntityDialogComponent<ProjectForm, Project>
      fields={fields}
      data={project}
      close={close}
      handleSubmit={handleSubmit}
    />
  );
}

export default ProjectModalComponent;
