import { ModalBody } from "./contact-form.styles";
import ModalComponent from "../../../modal/modal.component";
import { ChangeEvent, FormEvent, useState } from "react";
import LoaderComponent from "../../../loader/loader.component";
import { t } from "i18next";
import { TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
interface ContactFormState {
  name: string;
  email: string;
  message: string;
}

interface ContactFormErrors {
  name: boolean;
  email: boolean;
  message: boolean;
}

const enum FormField {
  Name = "name",
  Email = "email",
  Message = "message",
}

interface ContactFormModalProps {
  open: boolean;
  close: () => void;
}

function ContactFormComponent({ open, close }: ContactFormModalProps) {
  const initialState: ContactFormState = {
    name: "",
    email: "",
    message: "",
  };
  const initialErrors: ContactFormErrors = {
    name: false,
    email: false,
    message: false,
  };

  const [isSending, setIsSending] = useState<boolean>(false);

  const [state, setState] = useState<ContactFormState>(initialState);

  const [errors, setErrors] = useState<ContactFormErrors>(initialErrors);

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setErrors((errors) => ({
      ...errors,
      [event.target.name as FormField]:
        event.target.value === "" || event.target.value == null,
    }));
    setState((currentState) => ({
      ...currentState,
      [event.target.name as FormField]: event.target.value,
    }));
  };

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    setIsSending(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...state }),
    })
      .then(() => {
        close();
        setIsSending(false);
        setState(initialState);
        setErrors(initialErrors);
      })
      .catch((error) => alert(error));

    event.preventDefault();
  };

  return (
    <ModalComponent
      open={open}
      body={
        isSending ? (
          <LoaderComponent />
        ) : (
          <ModalBody>
            <form onSubmit={(event) => handleSubmit(event)}>
              <TextField
                id={FormField.Name}
                label={t(FormField.Name)}
                variant="standard"
                name={FormField.Name}
                required
                InputProps={{
                  startAdornment: <PersonIcon />,
                }}
                error={errors.name}
                onChange={handleChange}
              />
              <TextField
                id={FormField.Email}
                label={t(FormField.Email)}
                variant="standard"
                name={FormField.Email}
                required
                InputProps={{
                  startAdornment: <EmailIcon />,
                }}
                error={errors.email}
                onChange={handleChange}
              />
              <TextField
                id={FormField.Message}
                label={t(FormField.Message)}
                variant="standard"
                name={FormField.Message}
                required
                InputProps={{
                  startAdornment: <ChatBubbleIcon />,
                }}
                error={errors.message}
                multiline
                onChange={handleChange}
              />

              <button className="button__primary" type="submit">
                {t("send")}
              </button>
            </form>
          </ModalBody>
        )
      }
      close={() => {
        close();
        setState(initialState);
        setErrors(initialErrors);
      }}
    ></ModalComponent>
  );
}

export default ContactFormComponent;
