import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  isAuthenticated: boolean;
  outlet: JSX.Element;
  redirectPath?: string;
}

function ProtectedRouteComponent({
  isAuthenticated,
  outlet,
  redirectPath = "/login",
}: ProtectedRouteProps): JSX.Element {
  return isAuthenticated ? (
    <>
      {outlet ?? (
        <>
          <Outlet />
        </>
      )}
    </>
  ) : (
    <Navigate to={{ pathname: redirectPath }} />
  );
}

export default ProtectedRouteComponent;
