import { Container, Grid } from "@mui/material";
import { FooterContainer } from "./footer.styles";
import QuickLinksComponent from "./components/quick-links/quick-links.component";
import SocialLinksComponent from "./components/social-links/social-links.component";
import LogoComponent from "./components/logo/logo.component";

function FooterComponent(): JSX.Element {
  //TODO => LOGO WHEN DESIGNED

  return (
    <FooterContainer>
      <Container>
        <Grid container spacing={1} sx={{ pt: 8 }}>
          <LogoComponent />
          <QuickLinksComponent />
        </Grid>
        <Grid container spacing={1} sx={{ pb: 4, pt: { xs: 4 } }}>
          <SocialLinksComponent />
        </Grid>
      </Container>
    </FooterContainer>
  );
}

export default FooterComponent;
