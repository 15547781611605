import { Card } from "@mui/material";
import styled from "styled-components";

export const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h2 {
    font-size: 20px;
    color: black;
    line-height: 24px;
  }
  .button__secondary {
    color: #8551dd;
  }
`;
