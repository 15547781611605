import { Grid } from "@mui/material";
import { JobTitle } from "./job-description.styles";
import JobDetailComponent from "./components/detail/job-detail.component";
import { useState } from "react";
import { t } from "i18next";
import { Job } from "../../../../../services/firebase/collections/jobs/jobs.service";

function JobDescriptionComponent({
  date,
  company,
  location,
  description,
  title,
  caption,
  usedTechnologies,
}: Partial<Job>): JSX.Element {
  const [open, setOpen] = useState(false);
  const close = (): void => setOpen(false);

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        pt: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <JobTitle>
        <span>{date}</span>
        <br />
        {title},
        <span>
          {` ${company}`},{` ${location}`}
        </span>
      </JobTitle>
      <p>{caption}</p>
      <button className="button__secondary" onClick={() => setOpen(true)}>
        {t("showDetails")}
      </button>
      <JobDetailComponent
        open={open}
        job={{
          description,
          usedTechnologies,
        }}
        close={close}
      />
    </Grid>
  );
}

export default JobDescriptionComponent;
