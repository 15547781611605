import {
  collection as firebaseCollection,
  doc,
  DocumentData,
  setDoc,
  WithFieldValue,
  getDocs,
  query,
  orderBy,
  updateDoc,
  deleteDoc,
  writeBatch,
  getDoc,
} from "firebase/firestore";
import { Language } from "../../../translations/translations";
import { FirebaseService } from "../firebase.service";

enum FirebaseCollection {
  SpanishJobs = "spanishJobs",
  EnglishJobs = "englishJobs",
  EnglishStudies = "englishStudies",
  SpanishStudies = "spanishStudies",
  SpanishLanguages = "spanishLanguages",
  EnglishLanguages = "englishLanguages",
  SpanishAboutMe = "spanishAboutMe",
  EnglishAboutMe = "englishAboutMe",
  SpanishProjects = "spanishProjects",
  EnglishProjects = "englishProjects",
}

export enum Collection {
  Jobs,
  Studies,
  AboutMe,
  Projects,
}

export abstract class CollectionService<
  T extends WithFieldValue<DocumentData>
> extends FirebaseService {
  protected abstract collection: Collection;

  private readonly spanishCollections: Record<Collection, FirebaseCollection> =
    {
      [Collection.Jobs]: FirebaseCollection.SpanishJobs,
      [Collection.Studies]: FirebaseCollection.SpanishStudies,
      [Collection.AboutMe]: FirebaseCollection.SpanishAboutMe,
      [Collection.Projects]: FirebaseCollection.SpanishProjects,
    };

  private readonly englishCollections: Record<Collection, FirebaseCollection> =
    {
      [Collection.Jobs]: FirebaseCollection.EnglishJobs,
      [Collection.Studies]: FirebaseCollection.EnglishStudies,
      [Collection.AboutMe]: FirebaseCollection.EnglishAboutMe,
      [Collection.Projects]: FirebaseCollection.EnglishProjects,
    };
  abstract save(data: T, language: Language): Promise<void>;

  abstract getAll(): any;

  async updateDocument(document: T, language: Language): Promise<void> {
    try {
      await updateDoc(
        doc(this.db, this.getCollection(language), document.id),
        document
      );
    } catch (error) {
      alert(error);
    }
  }

  async deleteDocument(id: string, language: Language): Promise<void> {
    try {
      await deleteDoc(doc(this.db, this.getCollection(language), id));
    } catch (error) {
      alert(error);
    }
  }

  protected async saveData(data: T | T[], language: Language): Promise<void> {
    try {
      const collection = this.getCollection(language);
      if (Array.isArray(data)) {
        const batch = writeBatch(this.db);
        data.forEach((d) => {
          const ref = doc(firebaseCollection(this.db, collection));
          batch.set(ref, d);
        });
        await batch.commit();
      } else await setDoc(doc(firebaseCollection(this.db, collection)), data);
    } catch (error) {
      throw error;
    }
  }

  protected async getDocuments(
    language: Language,
    ordered: boolean = true
  ): Promise<T[]> {
    try {
      return (
        await getDocs(
          ordered
            ? query(
                firebaseCollection(this.db, this.getCollection(language)),
                orderBy("order", "desc")
              )
            : query(firebaseCollection(this.db, this.getCollection(language)))
        )
      ).docs.map((doc) => ({ ...doc.data(), id: doc.id } as unknown as T));
    } catch (error) {
      throw error;
    }
  }

  protected async getById(id: string, language: Language): Promise<T> {
    try {
      const ref = doc(this.db, this.getCollection(language), id);
      const snapshot = await getDoc(ref);
      return snapshot.data() as T;
    } catch (error) {
      throw error;
    }
  }

  private getCollection(language: Language): FirebaseCollection {
    if (language === Language.ES)
      return this.spanishCollections[this.collection];
    return this.englishCollections[this.collection];
  }
}
