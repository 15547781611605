import { Grid } from "@mui/material";
import { t } from "i18next";
import { useContext } from "react";
import { StateContext } from "../../App";
import { AppState } from "../../services/firebase/collections/app/app.service";
import { SectionTitle, TextAccent } from "../../styled-components";
import ProjectCardComponent from "./components/project-card/project-card.component";

function ProjectsComponent(): JSX.Element {
  const state = useContext<AppState>(StateContext);

  const projects = state.isSpanish ? state.es.projects : state.en.projects;

  return (
    <>
      <Grid container spacing={1} sx={{ pt: 8, pb: 8 }} id="projects">
        <Grid item xs={12}>
          <SectionTitle>
            <TextAccent width="80%">
              <span>{t("personalProjects")}</span>
            </TextAccent>
          </SectionTitle>
        </Grid>
        {projects.map((project) => (
          <ProjectCardComponent key={project.id} project={project} />
        ))}
      </Grid>
    </>
  );
}

export default ProjectsComponent;
