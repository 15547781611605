import { TextField } from "@mui/material";
import { t } from "i18next";
import { FormContainer } from "./login-form.styles";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../../translations/translations";
import LoaderComponent from "../loader/loader.component";

const enum FormField {
  Email = "email",
  Password = "password",
}

interface LoginFormState {
  email: string;
  password: string;
}

function LoginFormComponent({
  login,
  isAuthenticated,
}: {
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
}): JSX.Element {
  const { i18n } = useTranslation();

  const initialState: LoginFormState = {
    email: "",
    password: "",
  };

  const [state, setState] = useState<LoginFormState>(initialState);

  const [isLoginIn, setIsLoginIn] = useState<boolean>(true);

  useEffect(() => {
    i18n.changeLanguage(Language.ES);
    if (!isAuthenticated) setIsLoginIn(false);
  }, [i18n, isAuthenticated]);

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setState((currentState) => ({
      ...currentState,
      [event.target.name as FormField]: event.target.value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoginIn(true);
    await login(state.email, state.password);
    setIsLoginIn(false);
  };

  return isLoginIn ? (
    <LoaderComponent></LoaderComponent>
  ) : (
    <FormContainer>
      <form onSubmit={(event) => handleSubmit(event)}>
        <TextField
          id={FormField.Email}
          label={t(FormField.Email)}
          variant="standard"
          name={FormField.Email}
          required
          onChange={handleChange}
        />
        <TextField
          id={FormField.Password}
          label={t(FormField.Password)}
          variant="standard"
          name={FormField.Password}
          type={FormField.Password}
          required
          onChange={handleChange}
        />

        <button className="button__primary" type="submit">
          {t("login")}
        </button>
      </form>{" "}
    </FormContainer>
  );
}

export default LoginFormComponent;
