import { Grid } from "@mui/material";
import { StudyDiv } from "./studies.styles";
import masterIcon from "../../../../img/icons/master.svg";
import degreeIcon from "../../../../img/icons/degree.svg";
import { Studies } from "../../../../services/firebase/collections/studies/studies.service";
import { CustomCard } from "../../../../styled-components";
function StudiesComponent({ studies }: { studies: Studies }): JSX.Element {
  return (
    <Grid item xs={12} md={6}>
      <CustomCard>
        {studies.map((study, index) => (
          <StudyDiv key={study.id}>
            <h2>{study.school}</h2>
            <div>
              <img
                src={index === 0 ? masterIcon : degreeIcon}
                alt="study-icon"
              />
              <p>{study.title}</p>
            </div>
          </StudyDiv>
        ))}
      </CustomCard>
    </Grid>
  );
}

export default StudiesComponent;
