import Grid from "@mui/material/Unstable_Grid2";
import aboutMePicture from "../../img/aboutMe.png";
import {
  CustomAvatarContainer,
  TextAccent,
  SectionTitle,
} from "../../styled-components";
import { ImageContainer } from "./about-me.styles";
import { t } from "i18next";
import { useContext } from "react";
import { AppState } from "../../services/firebase/collections/app/app.service";
import { StateContext } from "../../App";

function AboutMeComponent(): JSX.Element {
  const state = useContext<AppState>(StateContext);
  const aboutMe = state.isSpanish
    ? state.es.aboutMe.text
    : state.en.aboutMe.text;
  return (
    <>
      <Grid container spacing={1} sx={{ pt: 6 }}>
        <Grid xs={12} xsOffset={0} md={6} mdOffset={6}>
          <SectionTitle>
            <TextAccent width="80%">
              <span>{t("aboutMe")}</span>
            </TextAccent>
          </SectionTitle>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid xs={12} md={6} sx={{ pt: 3 }}>
          <CustomAvatarContainer
            $mobilemarginbottom="2rem"
            $mobilemargintop="0"
          >
            <ImageContainer className="external-div">
              <div>
                <img alt="profilePicture" src={aboutMePicture} />
              </div>
            </ImageContainer>
          </CustomAvatarContainer>
        </Grid>
        <Grid xs={12} md={6} sx={{ pt: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: aboutMe }}></div>
        </Grid>
      </Grid>
    </>
  );
}

export default AboutMeComponent;
