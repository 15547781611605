import {
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Grid,
} from "@mui/material";
import { Job } from "../../../../../../services/firebase/collections/jobs/jobs.service";
import { t } from "i18next";
import { CustomCard } from "./job-card.styles";

function JobCardComponent({
  job,
  deleteJob,
  openModal,
}: {
  job: Job;
  deleteJob: (id: string) => Promise<void>;
  openModal: (job: Job) => void;
}): JSX.Element {
  return (
    <Grid item xs={4} sx={{ p: 1 }}>
      <CustomCard>
        <div>
          <CardMedia
            sx={{ height: 140 }}
            image={
              job.img.includes("data:image")
                ? job.img
                : `data:image/png;base64,${job.img}`
            }
            title={job.title
              .toLocaleLowerCase()
              .replaceAll(" ", "-")
              .concat("job-image")}
          />
          <CardContent>
            <h2>{job.title}</h2>
            <Typography variant="body2" color="text.secondary">
              {job.caption}
            </Typography>
          </CardContent>
        </div>
        <CardActions>
          <button
            className="button__secondary"
            onClick={() => {
              const wantsToDelete = window.confirm("confirm");
              if (wantsToDelete) deleteJob(job!.id);
            }}
          >
            {t("delete")}
          </button>
          <button
            className="button__primary"
            onClick={(event) => {
              event.preventDefault();
              openModal(job);
            }}
          >
            {t("edit")}
          </button>
        </CardActions>
      </CustomCard>
    </Grid>
  );
}

export default JobCardComponent;
