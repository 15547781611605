import { FirebaseApp, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env["REACT_APP_API_KEY"],
  authDomain: process.env["REACT_APP_AUTH_DOMAIN"],
  projectId: process.env["REACT_APP_PROJECT_ID"],
  storageBucket: process.env["REACT_APP_STORAGE_BUCKET"],
  messagingSenderId: process.env["REACT_APP_MESSAGING_SENDER_ID"],
  appId: process.env["REACT_APP_API_ID"],
};

export abstract class FirebaseService {
  protected readonly app: FirebaseApp;

  protected readonly db: Firestore;

  constructor() {
    this.app = initializeApp(firebaseConfig);

    this.db = getFirestore(this.app);
  }
}
