import styled from "styled-components";

export const JobTitle = styled.h2`
  font-size: 18px;
  line-height: 22px;
  span {
    font-weight: 400;
    color: #a8a5a5;
  }
`;
