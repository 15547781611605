import spanish from "./es.json";
import english from "./en.json";
export enum Language {
  EN = "en",
  ES = "es",
}

export class Translations {
  static readonly spanish = {
    es: {
      translation: spanish,
    },
  };

  static readonly english = {
    en: {
      translation: english,
    },
  };
}
