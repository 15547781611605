import {
  BtnBold,
  BtnItalic,
  HtmlButton,
  ContentEditableEvent,
  EditorProvider,
  Toolbar,
  Editor,
} from "react-simple-wysiwyg";

interface TextEditorComponentProps {
  html: string;
  name: string;
  height?: string;
  onChange: (event: ContentEditableEvent) => void;
}

function TextEditorComponent({
  html,
  name,
  height,
  onChange,
}: TextEditorComponentProps): JSX.Element {
  return (
    <EditorProvider>
      <Editor
        value={html}
        onChange={onChange}
        style={{ height: height ?? "40svh" }}
        name={name}
      >
        <Toolbar
          style={{
            justifyContent: "space-evenly",
          }}
        >
          <BtnBold />
          <BtnItalic />
          <HtmlButton />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
}

export default TextEditorComponent;
