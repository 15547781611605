import { Language } from "../../../../translations/translations";
import { Collection, CollectionService } from "../collection.service";

type Education = {
  id: string;
  title: string;
  school: string;
};

export type Studies = Education[];

export class StudiesService extends CollectionService<Education> {
  protected readonly collection = Collection.Studies;

  async save(studies: Studies | Education, language: Language): Promise<void> {
    try {
      await this.saveData(studies, language);
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  }

  async getAll(): Promise<{ es: Studies; en: Studies }> {
    try {
      const [es, en] = await Promise.all([
        this.getDocuments(Language.ES),
        this.getDocuments(Language.EN),
      ]);
      return { es, en };
    } catch (error) {
      console.error("Error retrieving document: ", error);
      throw error;
    }
  }
}
