import { AppBar, Toolbar, Container, Grid } from "@mui/material";
import { NavbarContainer } from "./nav-bar.styles";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink } from "react-router-dom";
interface NavBarComponentProps {
  links: {
    label: string;
    path: string;
  }[];
  logout: () => Promise<void>;
}

function NavBarComponent({ links, logout }: NavBarComponentProps): JSX.Element {
  return (
    <AppBar position="fixed" color="secondary">
      <Toolbar>
        <Container fixed>
          <Grid container>
            <NavbarContainer item xs={12}>
              {links.map((link, index) => (
                <NavLink
                  key={link.path + index}
                  to={link.path}
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "700" : "",
                      color: isActive ? "#8551dd" : "white",
                    };
                  }}
                  end
                >
                  {link.label}
                </NavLink>
              ))}
              <LogoutIcon onClick={() => logout()} />
            </NavbarContainer>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default NavBarComponent;
