import { Grid } from "@mui/material";
import { t } from "i18next";
import { Section } from "../../../../views/home/home.component";
import { FooterColumn } from "../../footer.styles";
import { scroller } from "react-scroll";

function QuickLinksComponent(): JSX.Element {
  function scrollTo(component: string): void {
    scroller.scrollTo(component, {
      duration: 1500,
      delay: 0,
      smooth: true,
    });
  }

  return (
    <>
      <FooterColumn
        container
        xs={12}
        md={3}
        sx={{ display: { xs: "initial", md: "none" } }}
      >
        <Grid item xs={12}>
          <h2>{t("quickLinks")}</h2>
        </Grid>
        <Grid item xs={12}>
          <ul>
            <li>
              <p onClick={() => scrollTo(Section.Header)}>{t("home")}</p>
            </li>
            <li>
              <p onClick={() => scrollTo(Section.AboutMe)}>{t("aboutMe")}</p>
            </li>
            <li>
              <p onClick={() => scrollTo(Section.WorkExperience)}>
                {t("workExperience")}
              </p>
            </li>
            <li>
              <p onClick={() => scrollTo(Section.ExtraInfo)}>
                {t("languages")} & {t("studies")}
              </p>
            </li>
            <li>
              <p onClick={() => scrollTo(Section.Projects)}>
                {t("personalProjects")}
              </p>
            </li>
            <li>
              <a href="privacy-policy">{t("privacyPolicy")}</a>
            </li>
          </ul>
        </Grid>
      </FooterColumn>
      <FooterColumn
        container
        xs={12}
        md={3}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid item xs={12}>
          <h2>{t("quickLinks")}</h2>
        </Grid>
        <Grid item xs={12} md={6}>
          <ul>
            <li>
              <p onClick={() => scrollTo(Section.Header)}>{t("home")}</p>
            </li>
            <li>
              <p onClick={() => scrollTo(Section.AboutMe)}>{t("aboutMe")}</p>
            </li>
            <li>
              <p onClick={() => scrollTo(Section.WorkExperience)}>
                {t("workExperience")}
              </p>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <ul>
            <li>
              <p onClick={() => scrollTo(Section.ExtraInfo)}>
                {t("languages")} & {t("studies")}
              </p>
            </li>
            <li>
              <p onClick={() => scrollTo(Section.Projects)}>
                {t("personalProjects")}
              </p>
            </li>
            <li>
              <a href="privacy-policy">{t("privacyPolicy")}</a>
            </li>
          </ul>
        </Grid>
      </FooterColumn>
    </>
  );
}

export default QuickLinksComponent;
