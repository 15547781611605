import { Grid } from "@mui/material";
import { FooterColumn } from "../../footer.styles";
import linkedin from "../../../../img/icons/linkedin.svg";
import github from "../../../../img/icons/github.svg";

function SocialLinksComponent(): JSX.Element {
  return (
    <FooterColumn>
      <Grid item xs={12}>
        <a
          href="https://www.linkedin.com/in/rubenfdezlopesino280994/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="linkedin" />
        </a>

        <a
          href="https://github.com/RubenFLopesino"
          target="_blank"
          rel="noreferrer"
        >
          <img className="ml-1" src={github} alt="github" />
        </a>
      </Grid>
    </FooterColumn>
  );
}

export default SocialLinksComponent;
