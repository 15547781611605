import {
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { FirebaseService } from "../firebase.service";

export class AuthService extends FirebaseService {
  private readonly _auth: Auth;

  constructor() {
    super();
    this._auth = getAuth(this.app);
  }

  async login(email: string, password: string): Promise<string | undefined> {
    try {
      const user = await signInWithEmailAndPassword(
        this._auth,
        email,
        password
      );
      return await user.user.getIdToken();
    } catch (error) {
      console.error("Error on login: " + error);
    }
  }

  async logout(): Promise<void> {
    try {
      await signOut(this._auth);
    } catch (error) {
      console.error("Error on login: " + error);
    }
  }

  get auth(): Auth {
    return this._auth;
  }
}
