import { Grid } from "@mui/material";
import { ExperienceTag } from "./header.styles";
import profilePicture from "../../img/profilePicture.png";
import { ButtonsContainer } from "./header.styles";
import { t } from "i18next";
import { useState } from "react";
import {
  CustomAvatar,
  CustomAvatarContainer,
  TextAccent,
} from "../../styled-components";
import ContactFormComponent from "./components/contact-form/contact-form.component";
import { scroller } from "react-scroll";
import { Section } from "../../views/home/home.component";

function HeaderComponent(): JSX.Element {
  const [experienceYears] = useState<number>(new Date().getFullYear() - 2018);
  const [open, setOpen] = useState<boolean>(false);

  const close = (): void => setOpen(false);

  return (
    <Grid container spacing={1} sx={{ pb: 8 }}>
      <Grid item xs={12} md={8}>
        <h1>
          <span>{t("hello")},</span> <br />
          <span>{t("iam")} </span>
          <TextAccent>
            <span>Rubén Fernández</span>
          </TextAccent>
          <br />
          <TextAccent>Lopesino</TextAccent>
        </h1>
        <ButtonsContainer>
          <button className="button__primary" onClick={() => setOpen(true)}>
            {t("contactMe")}
          </button>
          <button
            className="button__secondary"
            onClick={() =>
              scroller.scrollTo(Section.Projects, {
                duration: 1500,
                delay: 0,
                smooth: true,
              })
            }
          >
            {t("exploreProjects")}
          </button>
        </ButtonsContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomAvatarContainer>
          <CustomAvatar alt="profilePicture" src={profilePicture} />
          <ExperienceTag>
            <p>
              <span className="years-tag">{`${
                experienceYears < 10 ? "0" + experienceYears : experienceYears
              }`}</span>
              <span>{t("yearsOfExperience")}</span>
            </p>
          </ExperienceTag>
        </CustomAvatarContainer>
      </Grid>
      <ContactFormComponent open={open} close={close}></ContactFormComponent>
    </Grid>
  );
}

export default HeaderComponent;
