import { Grid } from "@mui/material";
import { t } from "i18next";
import { SectionTitle, TextAccent } from "../../styled-components";
import LanguagesComponent from "./components/languages/languages.component";
import StudiesComponent from "./components/studies/studies.component";
import { useContext } from "react";
import { StateContext } from "../../App";
import { AppState } from "../../services/firebase/collections/app/app.service";

function ExtraInfoComponent(): JSX.Element {
  const state = useContext<AppState>(StateContext);

  const studies = state.isSpanish ? state.es.studies : state.en.studies;

  return (
    <>
      <Grid container spacing={1} sx={{ display: { xs: "none", md: "flex" } }}>
        <Grid item xs={12} md={6}>
          <SectionTitle>
            <TextAccent width="80%">
              <span>{t("studies")}</span>
            </TextAccent>
          </SectionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionTitle>
            <TextAccent width="80%">
              <span>{t("languages")}</span>
            </TextAccent>
          </SectionTitle>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ display: { xs: "none", md: "flex" }, pb: 8 }}
      >
        <StudiesComponent studies={studies} />
        <LanguagesComponent />
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ display: { xs: "initial", md: "none" }, pb: 8 }}
      >
        <Grid item xs={12}>
          <SectionTitle>
            <TextAccent width="80%">
              <span>{t("studies")}</span>
            </TextAccent>
          </SectionTitle>
        </Grid>
        <StudiesComponent studies={studies} />

        <Grid item xs={12}>
          <SectionTitle>
            <TextAccent width="80%">
              <span>{t("languages")}</span>
            </TextAccent>
          </SectionTitle>
        </Grid>
        <LanguagesComponent />
      </Grid>
    </>
  );
}

export default ExtraInfoComponent;
